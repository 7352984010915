import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { isPlatformBrowser } from '@angular/common';
import {
    ActivatedRouteAction,
    CoreAppActionTypes,
    EuiGrowlService,
    UpdateAppConnectionAction,
    UpdateCurrentModuleAction,
} from '@eui/core';
import { Action } from '@ngrx/store';

@Injectable()
export class CoreAppEffects {
    getAppConnectionInformation: Observable<Action>;
    activatedRoute: Observable<Action>;
    updateAppConnection;

    constructor(
        protected actions: Actions,
        protected translateService: TranslateService,
        protected asService: EuiGrowlService,
        @Inject(PLATFORM_ID) protected platformId: unknown,
    ) {
        this.getAppConnectionInformation = createEffect(() => {
            if (isPlatformBrowser(this.platformId)) {
                return merge(
                    fromEvent(window, 'online').pipe(map(() => true)),
                    fromEvent(window, 'offline').pipe(map(() => false)),
                ).pipe(
                    map(
                        (connected) => new UpdateAppConnectionAction(connected),
                    ),
                );
            } else {
                return of(new UpdateAppConnectionAction(false));
            }
        });
        this.activatedRoute = createEffect(() =>
            this.actions.pipe(
                ofType(CoreAppActionTypes.ACTIVATED_ROUTE),
                map((action: ActivatedRouteAction) => action.payload),
                mergeMap((route) => {
                    // extract the module name, if defined
                    const module = route.snapshot.data['module'] || 'undefined';
                    return [new UpdateCurrentModuleAction(module)];
                }),
            ),
        );
        this.updateAppConnection = createEffect(
            () =>
                this.actions.pipe(
                    ofType(CoreAppActionTypes.UPDATE_APP_CONNECTION),
                    map((action: UpdateAppConnectionAction) => action.payload),
                    tap((connected) => {
                        // check if feature is enabled
                        // extract lifespan if any
                        const lifespan = 0;

                        // if the user needs to be informed about the connection status
                        if (connected) {
                            this.translateService
                                .get('eui.CONNECTED')
                                .subscribe((translation) => {
                                    this.asService.growl(
                                        {
                                            severity: 'success',
                                            summary: 'MESSAGE',
                                            detail: translation,
                                            life: lifespan,
                                        },
                                        lifespan < 1,
                                        false,
                                    );
                                });
                        } else {
                            this.translateService
                                .get('eui.NOTCONNECTED')
                                .subscribe((translation) => {
                                    this.asService.growl(
                                        {
                                            severity: 'danger',
                                            summary: 'ERROR',
                                            detail: translation,
                                            life: lifespan,
                                        },
                                        lifespan < 1,
                                        false,
                                    );
                                });
                        }
                    }),
                ),
            { dispatch: false },
        );
    }
}
