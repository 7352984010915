import { InjectionToken } from '@angular/core';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { storeFreeze } from 'ngrx-store-freeze';
import { CoreState, reducers as coreReducers } from '@eui/core';

import { environment } from '../../environments/environment';

export interface AppState extends CoreState {
    router: fromRouter.RouterReducerState<any>;
}

// -----------------
// ------------ AOT
export const TOKEN = new InjectionToken<any>('AppReducers');

export function getReducers(): ActionReducerMap<AppState, any> {
    return {
        router: fromRouter.routerReducer,
        ...coreReducers,
    };
}

export const reducerProvider = [
    { provide: TOKEN, useFactory: getReducers },
];

// console.log all actions
export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return (state, action) => {
        const result = reducer(state, action);
        console.groupCollapsed(action.type);
        console.log('prev state', state);
        console.log('action', action);
        console.log('next state', result);
        console.groupEnd();

        return result;
    };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger, storeFreeze] : [];
